<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="14"
    viewBox="0 0 13 14"
    :class="classname"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.98159 11.6953L8.85706 10.8198L5.03884 7.00163L8.85706 3.18341L7.98159 2.30794L3.28791 7.00163L7.98159 11.6953Z" />
  </svg>
</template>

<script>
export default {
  name: 'ChevronLeft',
  props: {
    classname: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
svg {
  fill: currentColor;
}
</style>
