<template>
  <client-only>
    <div
      class="
        z-10
        w-full
        h-full
        flex
        justify-center
        items-center
        flex-col
      "
    >
      <div
        class="
          font-ui
          leading-tighter
        "
      >
        <div class="w-full py-12 lg:py-24">
          <div class="container px-4 md:px-6">
            <div
              class="flex flex-col items-center justify-center space-y-4 text-center"
            >
              <div class="space-y-2">
                <div class="flex flex-row justify-between items-center">
                  <div @click="handleBackAction">
                    <ChevronLeft
                      classname="h-8 w-8 cursor-pointer"
                      @click="handleBackAction"
                    />
                  </div>
                  <h1
                    class="text-center text-4xl font-bold tracking-tighter sm:text-6xl"
                  >
                    App Shutdown Notice
                  </h1>
                  <div class="h-2 w-2"></div>
                </div>
                <p
                  class="max-w-[900px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400 py-10"
                >
                  We regret to inform you that our app will be shutting down
                  soon. We appreciate your support and the time you've spent
                  using our app.<br />
                  Please read the following FAQs for more information.
                </p>
              </div>
            </div>
            <div class="mx-auto max-w-3xl flex flex-col gap-12  lg:max-w-5xl ">
              <div class="space-y-4">
                <h3 class="text-2xl font-bold">
                  What is the reason for shutting down the app?
                </h3>
                <p class="text-gray-500 dark:text-gray-400">
                  We have made the difficult decision to discontinue our
                  service. We sincerely apologize for any inconvenience this may
                  cause and are deeply grateful for the trust you have placed in
                  us. <br />
                  <br />Excitingly, we are now offering our infrastructure
                  through
                  <a
                    class="text-blue-600 visited:text-purple-600"
                    href="https://videodb.io?utm_source=spext&utm_medium=app&utm_campaign=shut_down_notice"
                    >VideoDB</a
                  >, a robust database designed for video storage, search, and
                  streaming. We warmly invite you to join us there and continue
                  the journey with us.
                  <br />
                  Thank you for your support and understanding
                </p>
              </div>
              <div class="space-y-4">
                <h3 class="text-2xl font-bold">
                  Can I get a refund for my subscription?
                </h3>
                <p class="text-gray-500 dark:text-gray-400">
                  We have already processed refunds for any active subscriptions.
                  Please allow some time for the refund to be reflected in your
                  account.
                </p>
              </div>
              <div class="space-y-4">
                <h3 class="text-2xl font-bold">
                  How can I contact support for further assistance?
                </h3>
                <p class="text-gray-500 dark:text-gray-400">
                  If you have any questions or need assistance, please contact
                  our support team at
                  <a
                    class="underline underline-offset-2"
                    href="mailto:hello@spext.co"
                  >
                    hello@spext.co
                  </a>
                  . We are here to help you during this transition.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </client-only>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ChevronLeft from '@/components/base/icons/ChevronLeft.vue'

export default {
  name: 'ShutDownNotice',
  components: {
    ChevronLeft
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal',
      logout: 'app/logout'
    }),
    handleBackAction() {
      console.log('handling back action')
      this.logout()
    }
  },
  mounted() {}
}
</script>

<style>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.animate-fadeIn {
  animation: fadeIn 2s ease-in forwards;
}
.animate-spin {
  animation: spin 1s linear infinite;
}
</style>
